import React from 'react';

import usePrevious from 'shared/hooks/use-previous';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import ProgressiveQuizContext from 'quizzes/components/context';
import { QuizQuestionType } from 'redux/schemas/models/progressive-quiz';
import { wrapThunkAction } from 'redux/utils';
import { useDispatch } from 'react-redux';
import { createQuizQuestionSubmission } from 'redux/actions/quizzes';

const useProgressiveQuizNavigation = (canFetchNextQuestionInStatetment = true) => {
  const {
    mode,
    reveal,
    questions,
    fetchQuestion,
    progressiveQuiz,
    currentQuestion,
    notMetRequirement,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    currentQuestionResponse,
    isSubmitting,
    setIsSubmitting,
  } = React.useContext(ProgressiveQuizContext);

  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const previousIndex = usePrevious(currentQuestionIndex);
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;
  const [editModeMaxIndexAllowed, setEditModeMaxIndexAllowed] = React.useState(-1);
  const isStatement = currentQuestion.type === QuizQuestionType.STATEMENT;

  const areAttemptsLeft = currentQuestion.completedQuestionAttempts
    < currentQuestion.totalQuestionAttempts;

  const canStillMeetRequirement = !notMetRequirement();

  const index = currentQuestion.questionIndex;
  const dispatch = useDispatch();

  const canFetchNextQuestion = (currentQuestionResponse || (isStatement && canFetchNextQuestionInStatetment))
    && (currentQuestionResponse?.isCorrect
      // If it's not correct and can still meet requiremend and question is failed
      || (canStillMeetRequirement && !areAttemptsLeft) || isStatement)
    // Is last answerable question
    && (index === editModeMaxIndexAllowed)
    // Is not last question
    && (index !== (progressiveQuiz.totalQuestions - 1));

  React.useEffect(() => {
    if (canFetchNextQuestion) {
      setEditModeMaxIndexAllowed((prev) => prev + 1);
    }
  }, [canFetchNextQuestion]);

  React.useEffect(() => {
    if (((previousIndex === null) && currentQuestionIndex) || (currentQuestionIndex > editModeMaxIndexAllowed)) {
      setEditModeMaxIndexAllowed(currentQuestionIndex);
    }
  }, [previousIndex, currentQuestionIndex, editModeMaxIndexAllowed]);

  const previous = () => {
    const newQuestionIndex = currentQuestionIndex - 1;

    if (isAnswerMode) {
      setIsSubmitting(true);
      fetchQuestion({
        page: newQuestionIndex + 1,
      }).then(() => {
        setIsSubmitting(false);
      });
    } else {
      setCurrentQuestionIndex(newQuestionIndex);
    }
  };

  const next = () => {
    const newQuestionIndex = currentQuestionIndex + 1;

    if (isAnswerMode) {
      setIsSubmitting(true);
      if (isStatement && !currentQuestionResponse) {
        wrapThunkAction(dispatch(createQuizQuestionSubmission({
          questionId: currentQuestion!.id,
          userResponse: '',
        }))).then(() => {
          fetchQuestion({
            page: newQuestionIndex + 1,
          }).then(() => {
            setIsSubmitting(false);
          });
        }).catch(() => {
          setIsSubmitting(false);
        });
        return;
      }
      fetchQuestion({
        page: newQuestionIndex + 1,
      }).then(() => {
        setIsSubmitting(false);
      });
    } else {
      setCurrentQuestionIndex(newQuestionIndex);
    }
  };

  const isPreviousDisabled = !currentQuestionIndex || isSubmitting;

  const maxIndexAllowed = (() => {
    if (isEditMode || reveal) {
      return progressiveQuiz.totalQuestions - 1;
    }

    if (isAnswerMode) {
      return editModeMaxIndexAllowed;
    }

    return questions.length - 1;
  })();

  const isNextDisabled = (() => {
    if (isEditMode) {
      return currentQuestionIndex === progressiveQuiz.totalQuestions - 1;
    }

    return currentQuestionIndex >= maxIndexAllowed || isSubmitting;
  })();


  return {
    next,
    previous,
    isNextDisabled,
    maxIndexAllowed,
    isPreviousDisabled,
  };
};

export default useProgressiveQuizNavigation;
